import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { GAME_SYMBOL_APEX } from "@/game-apex/definition-symbol.mjs";
import apexStatic from "@/game-apex/static.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function AccountsApex() {
  const state = useSnapshot(readState);
  const {
    settings: {
      loggedInAccounts: { apex },
    },
  } = state;
  const profiles = useMemo(() => {
    return Object.entries(apex).reduce((profiles, [key, profile]) => {
      if (profile)
        profiles.push({
          displayName: profile.username,
          key,
          avatar: apexStatic.getLegendImage(profile.hoveredChampionApexId),
        });
      return profiles;
    }, []);
  }, [apex]);
  return (
    <Accounts
      profiles={profiles}
      gameShort={GAME_SHORT_NAMES[GAME_SYMBOL_APEX]}
      autoDetectText={[
        "apex:settings.autoDetectProfile",
        "Hey! I can automatically detect your profile. Just open Apex and log into the account you want to connect.",
      ]}
    />
  );
}
